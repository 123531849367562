@use "src/style/base";

.chartContainer {
  display: flex;
  justify-content: center;
}

.chart {
  img {
    max-width: 1350px;
    max-height: 475px;
    width: 100%;
  }
}
