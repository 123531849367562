@use "src/style/base";

.vertraegeTableRow > td {
  cursor: pointer;
}

.vertraegeTableRowContent.open,
.vertraegeTableRow.open {
  background: #f6f6f6;
}

.vertraegeTableCellContent {
  padding-bottom: 0;
  padding-top: 0;
}

.vertraegeTableCellContentOpen {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

.vertraegeDataTableCol1 {
  width: 60px;
}

.vertraegeDataTableCol5 {
  width: 180px;
  text-align: right;
}

.vertraegeDataTableHeaderRow > th > * {
  font-weight: bold;
  color: base.$primary-color;
}

.line {
  margin-bottom: 1rem;
}
