@use "src/style/base";

.page {
  padding: 2rem 2rem 0;
  flex-wrap: wrap;
}

.textContainer {
  flex: 2;
  min-width: 200px;
}

.imageContainer {
  flex: 3;
  img {
    max-width: 730px;
    min-width: 300px;
    width: 100%;
  }
}

.text {
  color: base.$grey-800;
}

.emphasis {
  color: base.$red;
}

@include base.media-breakpoint-down(md) {
  .page {
    padding-bottom: 2rem;
  }
}
