$app-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1536px,
) !default;

@function breakpoint-min($name, $breakpoints: $app-breakpoints) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $app-breakpoints) {
  $max: map-get($breakpoints, $name);

  @return if($max and $max > 0, $max - 0.02, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $app-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $app-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
