@use "src/style/base";
@import "src/style/breakpoints";

.chartContainer {
  display: flex;
  justify-content: center;
}

.chart {
  img {
    max-width: 1100px;
    width: 100%;
  }
}
