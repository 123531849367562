@use "src/style/base";
@use "src/style/breakpoints";

.backgroundClass {
  background-repeat: repeat;
  background-size: unset;
}

.optionsContainer {
  padding-bottom: 1rem;
}

.optionContainer {
  display: flex;
  img {
    width: 100%;
    max-width: 264px;
  }
}

.basisContainer {
  justify-content: flex-end;
  padding-right: 5rem;
}

.premiumContainer {
  justify-content: flex-start;
  padding-left: 5rem;
}

@include base.media-breakpoint-down(lg) {
  .optionContainer {
    height: unset;
    padding: 2rem 0;
    img {
      max-width: 300px;
    }
  }
  .basisContainer {
    justify-content: center;
    padding-right: 0;
  }

  .premiumContainer {
    justify-content: center;
    padding-left: 0;
  }

  .backgroundClass {
    background-image: none !important;
    background-size: unset;
  }
}
