@use "breakpoints";
@use "colors";

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.2em;
  hyphens: auto;
}

h1,
h2,
h3,
h4 {
  color: colors.$primary-color;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.9375rem;
  line-height: 1.15;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3125rem;
}

h5 {
  font-size: 1.15rem;
}

h6 {
  font-size: 1rem;
}

@include breakpoints.media-breakpoint-up(md) {
  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.3125rem;
  }

  h5 {
    font-size: 1.1rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 2rem;
  }
}

@include breakpoints.media-breakpoint-up(lg) {
  h1 {
    font-size: 3.75rem;
  }

  h2 {
    font-size: 2.9375rem;
  }

  h3 {
    font-size: 1.8125rem;
  }
}
