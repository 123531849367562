@use "src/style/base";

.circularDisplayContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .MuiCircularProgress-root {
      width: 180px !important;
      height: 180px !important;
    }
  }

  .backgroundCircle {
    position: absolute;

    :global {
      .MuiCircularProgress-circle {
        fill: white;
        stroke: base.$grey-400;
        stroke-dashoffset: 0 !important;
      }
    }
  }

  .circularProgressLabel {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
  }
}

.dinScoreLabelText {
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: base.$primary-color;
}

.dinScoreLabelValue {
  font-size: 1.5rem;
  color: base.$secondary-color;
  font-weight: bold;
}

.saldoLabelText {
  font-size: 1rem;
  line-height: 1.2rem;
  color: base.$primary-color;
}

.saldoLabelValue {
  font-size: 1.5rem;
  font-weight: bold;
  color: base.$success;
}

.negative {
  color: base.$error;
}

.saldoSubInfo {
  font-size: 0.7rem;
  color: base.$grey-700;
}
