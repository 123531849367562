@use "src/style/base";

.tooltipIcon {
  color: base.$grey-500;
}

.tooltipIcon:hover {
  color: base.$secondary-color;
}

div.tooltipPopper {
  background-color: base.$secondary-color;
  font-size: base.$tooltip;
  padding: 0.5rem 1rem;
}
