@use "src/style/base";
@import "src/style/breakpoints";

.content {
  flex-grow: 1;
  min-height: calc(100vh - #{base.$appBarHeight});
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.navigation {
  justify-content: flex-end;
  gap: 2rem;
  padding: 0 1rem 1rem 0;
}

.navigationButton {
  min-width: 10rem;
}
