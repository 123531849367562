@use "src/style/base";
@import "src/style/breakpoints";

.appBar {
  height: base.$appBarHeight;
  justify-content: center;
  padding: 0 2rem;
}

.toolbar {
  display: flex;
  padding: 0 2rem;
}

.left,
.center,
.right {
  display: flex;
  align-items: flex-start;
  padding: 0 0.5rem;
}

.left {
  flex-basis: 25%;
}

.center {
  flex-basis: 50%;
  justify-content: center;
}

.right {
  flex-basis: 25%;
  justify-content: flex-end;
  gap: 1rem;
}

.logo {
  display: flex;
}

.logoMobile {
  display: none;
}

@include media-breakpoint-down(md) {
  .toolbar {
    padding: 0 1rem;
  }

  .logo {
    display: none;
  }

  .logoMobile {
    display: flex;
    img {
      width: 6rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .toolbar {
    padding: 0 0.5rem;
  }
}
