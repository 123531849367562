@use "src/style/base";

.screenContentContainer {
  display: flex;
  justify-content: center;
}

.screenContent {
  max-width: base.breakpoint-max(xl);
  flex-grow: 1;
  padding: 2rem 2rem 0 2rem;
}

@include base.media-breakpoint-down(md) {
  .screenContent {
    padding: 1rem;
  }
}
