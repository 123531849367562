@use "src/style/base";

.page {
  padding: 0 2rem;
  flex-wrap: wrap;
}

.diagrammContainer {
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 700px;
  }
}

.dokumentContainer {
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 390px;
  }
}
