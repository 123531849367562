@use "src/style/base";

.text {
  font-weight: normal;
  text-align: center;
  color: base.$grey-800;
}

.backgroundClass {
  background-repeat: repeat;
  background-size: unset;
}

.container {
  align-items: center;
  height: 100%;
}

.textContainer {
  padding: 1rem;
}

.imageContainer {
  img {
    width: 100%;
  }
}
