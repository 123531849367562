@use "src/style/base";

.accordion {
  background: base.$grey-100;
}

.accordion::before {
  height: 0;
}

.odd {
  background: base.$grey-200;
}

div.accordionSummary {
  min-height: 0 !important;
}

.accordionSummary :global .MuiAccordionSummary-content {
  margin: 0;
}

.accordionDetails {
  border-top: 1px solid base.$grey-300;
}

.accordionActions {
  padding: 0 1rem 1rem 0;
}

.accordion.displayAsDeleted {
  background: base.$selective-deleted-background;
}
.accordion.displayAsNew {
  background: base.$selective-selected-background;
}
