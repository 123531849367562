@use "src/style/base";

.navigation {
  display: none;
}

@include base.media-breakpoint-up(md) {
  .navigation {
    display: flex;
  }
}

.navigationBox {
  display: flex;
  flex-grow: 1;
  gap: 2.5rem;
}
