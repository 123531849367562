@use "src/style/base";

.cockpit {
  gap: 2rem;
  text-align: center;
}

.cardRow {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

$outside-button-padding: 0rem;

.steeringWheel {
  min-height: 16rem;
  margin-top: 8rem;

  .wheelHandles {
    display: flex;
    justify-content: space-between;
    position: relative;

    .greenBox {
      background-color: base.$cockpit-handle;
      border-radius: 1rem;
      width: 10rem;
      height: 1.5rem;
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.5rem;
    }

    .leftButtonContainer {
      align-items: flex-end;
      padding-left: $outside-button-padding;
    }

    .rightButtonContainer {
      align-items: flex-start;
      padding-right: $outside-button-padding;
    }

    .customerDataButton,
    .reportButton {
      line-height: 4rem;
      font-size: 1.5rem;
      border-radius: 0.75rem;
      width: 24rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: base.$secondary-color;
        transform: scale(1.05);
      }
    }

    .reportButton {
      justify-content: flex-end;
    }

    .customerDataButton {
      justify-content: flex-start;
    }

    .buttonIcon {
      font-size: 2rem;
    }
  }

  .wheel {
    position: absolute;
    background-color: transparentize(base.$grey-200, 0.1);
    width: 57%;
    max-width: 60rem;
    left: 0;
    right: 0;
    margin: -6rem auto 0 auto;
    height: 20rem;
    border-radius: 6.5rem;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .wheelTopAccent {
      background-color: base.$grey-400;
      width: 60%;
      height: 6rem;
      border-radius: 6.5rem;
      position: absolute;
      margin-top: -3rem;
    }

    .wheelProgressContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: 100%;
      padding: 0 4rem;
    }

    .wheelLogo {
      display: flex;
      filter: grayscale(1);
      opacity: 0.3;
    }
  }
}

.error {
  display: flex;
  justify-content: center;
}
