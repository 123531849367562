$primary-color: #7c6a5a;
$secondary-color: #c8b89d;
$white: #ffffff;
$cockpit-handle: #b5a288;
$red: #d32f2f;
$yellow: #f9ec31;
$error: $red;
$success: #46ce35;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$guidance-personnel: #4ca873;
$guidance-career: #ba3322;
$guidance-mitnorm: $primary-color;
$guidance-company-lighter: #f2f9ff;
$guidance-company-light: #daecfc;
$guidance-company: #bbddf8;
$guidance-title: #363e48;
$guidance-subtitle: #999999;

$selective-selected: $secondary-color;
$selective-selected-background: transparentize($selective-selected, 0.8);
$selective-deleted-background: transparentize($error, 0.8);
