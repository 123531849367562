@use "src/style/base";

.progressBarContainer {
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.progressBar {
  width: 100%;
}

.progressClassName {
  height: 1.25rem;
  border-radius: 0.25rem;
  background-color: base.$grey-300;
}

.labelClassName {
  font-size: 1rem;
  font-weight: normal;
}
