@use "src/style/base";

$table-row-opacity: 0.9;
.table {
  th,
  td {
    border: none;
    border-left: 2px solid white;
    border-right: 2px solid white;
  }

  th {
    color: base.$primary-color;
    font-weight: bold;
    background-color: transparentize(base.$primary-color, $table-row-opacity);
  }

  tr:nth-child(even) {
    background-color: transparentize(base.$primary-color, $table-row-opacity);
  }
}

.circularDisplay {
  :global {
    .MuiCircularProgress-root {
      width: 32px !important;
      height: 32px !important;
    }
  }
}
