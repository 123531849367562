@use "src/style/base";

.selected {
  :global {
    .MuiInputBase-root.MuiInput-root {
      input,
      textarea,
      label {
        -webkit-text-fill-color: base.$selective-selected;
        border-radius: 5px;
        background-color: base.$selective-selected-background;
      }
    }

    .MuiInputBase-root.MuiInput-root::before {
      border-color: base.$selective-selected;
      border-bottom-style: solid;
    }
  }
}

.unselected {
  :global {
    .MuiInputBase-root.MuiInput-root::before {
      border-bottom-style: solid;
    }
  }
}

.selectedCheckbox {
  border-radius: 5px;
  background-color: base.$selective-selected-background;
}

.hiddenSwitch {
  visibility: hidden;
}

.selectiveSwitch {
  align-self: center;
}

.selectiveInputGroup {
  padding: 1rem;
}

.selectiveWrapper {
  align-items: center;
  width: 100%;
}
