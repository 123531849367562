@use "src/style/base";
@use "src/style/breakpoints";

.textContainer {
  width: 33%;
  min-width: min(35rem, 100%);
  padding-left: 6rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.text {
  font-weight: bold;
  color: base.$grey-800;
}

@include breakpoints.media-breakpoint-down(md) {
  .textContainer {
    padding: 0 2rem;
  }
}
