@use "src/style/base";

.commonSlider {
  color: base.$secondary-color;

  :global {
    .MuiSlider-rail {
      color: base.$grey-500;
    }

    .MuiSlider-track {
      border: none;
    }

    .MuiSlider-thumb {
      height: 12px;
      width: 12px;

      &:focus,
      &:hover,
      &.Mui-active,
      &.Mui-focusVisible {
        box-shadow: inherit;
      }

      &:before {
        display: none;
      }
    }

    .MuiSlider-valueLabel {
      line-height: 1;
      font-size: 11px;
      padding: 0;
      width: 32px;
      height: 32px;
      background: base.$secondary-color;

      &:before {
        display: none;
      }

      & > * {
        transform: rotate(45deg);
      }
    }
  }
}

.zeroThumb {
  color: base.$grey-700;
  :global {
    .MuiSlider-valueLabel {
      background: base.$grey-500;
    }
  }
}

.horizontalLabelContainer {
  justify-content: space-between;
}

.horizontalSlider {
  height: 2px;
  padding-top: 2.25rem;

  :global {
    .MuiSlider-valueLabel {
      border-radius: 50% 50% 50% 0;
      transform-origin: bottom left;
      transform: translate(50%, -100%) rotate(-45deg) scale(0);

      &.MuiSlider-valueLabelOpen {
        transform: translate(50%, -100%) rotate(-45deg) scale(1);
      }
    }
  }
}

.verticalSliderContainer {
  height: 12rem;
  align-items: center;
  gap: 0.5rem;
}

.verticalLabelContainer {
  align-items: center;
}

.verticalSlider {
  width: 2px;

  :global {
    .MuiSlider-valueLabel {
      border-radius: 50% 50% 0 50%;
      transform-origin: center;
      transform: translate(50%, -110%) rotate(-45deg) scale(0);

      &.MuiSlider-valueLabelOpen {
        transform: translate(0%, -110%) rotate(-45deg) scale(1);
      }
    }
  }
}
