@use "src/style/base";

.vertragEntryLabel {
  display: flex;
  align-items: flex-end;
}

.topLabel {
  align-items: flex-start;
}
