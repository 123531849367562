@use "src/style/base";

.box {
  display: flex;
  flex-direction: column;
  background-color: base.$grey-100;
  text-align: left;
}

hr {
  border-top: 1px solid base.$grey-300;
  margin: 0;
}

.titleContainer {
  padding: 0 1rem;
}

.bodyContainer {
  padding: 1rem;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 2.5rem 0;
}
