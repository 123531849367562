@use "src/style/base";

.menu {
  padding: 1rem;

  ul {
    margin: 0.5rem;
    padding-left: 2rem;
  }

  li {
    padding: 0.25rem 0;
  }

  li.selected::marker {
    color: base.$secondary-color;
  }

  a {
    text-decoration: none;
  }
}

.menuItemList {
  width: clamp(200px, 20vw, 350px);
}

.menuHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.menuItem {
  color: base.$grey-500;
  text-decoration: none;
}

.selected {
  color: base.$primary-color;

  > p {
    font-weight: bold;
  }
}

.mobileMenu {
  border-radius: 0;
  z-index: 1;
  cursor: pointer;

  .iconButton {
    color: base.$primary-color;
  }
}

.mobileMenu .iconButton.selected {
  color: base.$secondary-color;
}

.mobileMenuButton {
  align-self: flex-start;
  margin-top: 1.5rem;
}
