@use "src/style/base";

.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapContainer {
  display: flex;
  justify-content: center;
  img {
    max-width: 640px;
    width: 100%;
  }
}

.partnerContainer {
  img {
    max-width: 400px;
    width: 100%;
  }
}
