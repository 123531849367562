@use "src/style/base";

.insuranceCountLeft {
  min-width: 1rem;
  text-align: left;
}

.insuranceCountCenter {
  padding: 0 0.2rem;
}

.insuranceCountRight {
  min-width: 1rem;
  text-align: right;
}
