.loginPage {
  display: flex;
  height: 100vh;
}

.imageArea {
  flex: 1;
  display: flex;
  height: 100vh;
  background-image: url("../../assets/images/login/login-background.webp");
  background-size: cover;
  background-position: right;

  justify-content: center;
  align-content: center;

  .contentImage {
    width: 80%;
    height: auto;
    object-fit: contain;
  }
}

.formArea {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .formBox {
    max-width: 25em;
    width: 100%;
    padding: 1.5em;
    text-align: left;
  }

  .footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 2rem;
  }

  .footerLinks {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}

@media screen and (max-width: 800px) {
  .loginPage {
    flex-direction: column;
    height: 100vh;
  }
  .imageArea {
    display: none;
  }
  .formArea {
    flex: 1;
    height: 100vh;
  }
}
