@use "src/style/base";

.imageContainer {
  display: flex;
  justify-content: center;
  padding: 1rem;
  img {
    max-width: 1200px;
    width: 100%;
  }
}
