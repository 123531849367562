@use "src/style/base";

.block {
  background-color: base.$grey-300;
  padding: 1rem;
}

.title {
  color: base.$primary-color;
}

.fullWidth {
  width: 100%;
}

.noPadding {
  padding: 0;
}
