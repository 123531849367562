@use "src/style/base";

.uebersichtBox {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

.listBlocks {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.listTitle {
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin: 0;

  > li {
    text-align: start;
    margin-top: 0.4em;
    margin-right: 0.4em;
  }
}

.sublist {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    text-align: start;
    margin-top: 0.4em;
    margin-right: 0.4em;
  }
}

@include base.media-breakpoint-down(md) {
  .uebersichtBox {
    width: 100%;
  }
}
