@use "src/style/base";

.mobileNavigation {
  display: flex;
}

.menuIconButton {
  color: base.$primary-color;
}

.menu {
  display: block;
}

.menuItemText {
  text-align: center;
}

@include base.media-breakpoint-up(md) {
  .mobileNavigation {
    display: none;
  }
  .menu {
    display: none;
  }
}
