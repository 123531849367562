@use "src/style/base";

.titleContainer {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.titleText {
  margin-top: 0;
  margin-bottom: 0;
}

.titleButton {
  padding-bottom: 0.5rem;
}
