@use "src/style/base";

.underline {
  width: 2.5rem;
  height: 5px;
  background-color: base.$guidance-subtitle;
}

.invisible {
  background-color: transparent;
}
