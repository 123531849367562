@use "src/style/base";

.chartContainer {
  display: flex;
  justify-content: center;
  padding-left: 4rem;
}

.chart {
  img {
    max-width: 900px;
    width: 100%;
  }
}
