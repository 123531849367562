@use "src/style/base";
@import "src/style/breakpoints";

.page {
  padding: 0 2rem;
  align-items: center;
  gap: 2rem;
}

.map {
  flex: 1;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 345px;
  }
}

.textContainer {
  flex: 1;
}

.textBox {
  width: min(25rem, 100%);
}

.text {
  color: base.$grey-800;
}

.emphasis {
  color: base.$grey-400;
}

@include media-breakpoint-down(md) {
  .page {
    flex-direction: column;
  }

  .text {
    text-align: center;
  }
}
