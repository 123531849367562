@use "src/style/base";

.textContainer {
  width: 33%;
  min-width: min(25rem, 100%);
  padding-left: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
}

.text {
  font-weight: normal;
  color: base.$grey-900;
}

.dinButton {
  color: base.$grey-900;
  font-weight: bold;
  letter-spacing: 0.25rem;
  padding: 0.5rem 2rem;
  background-color: base.$grey-300;
}

.dinButton:hover {
  background-color: base.$grey-400;
}
