@use "src/style/base";

.headerTooltip {
  padding-left: 0.5rem;
  align-self: center;
}

.removeChildButton {
  align-self: center;
}

.displayAsDeleted {
  background-color: base.$selective-deleted-background;
}

.displayAsNew {
  background-color: base.$selective-selected-background;
}
