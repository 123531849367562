@use "style/base";

body {
  margin: 0;
  font-family: -apple-system, proxima-nova, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "proxima-nova";
  src: url("assets/fonts/proxima-nova/proxima-nova-700_1.woff2") format("woff2"),
    url("assets/fonts/proxima-nova/proxima-nova-700_2.woff") format("woff"),
    url("assets/fonts/proxima-nova/proxima-nova-700_3.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("assets/fonts/proxima-nova/proxima-nova-400_1.woff2") format("woff2"),
    url("assets/fonts/proxima-nova/proxima-nova-400_2.woff") format("woff"),
    url("assets/fonts/proxima-nova/proxima-nova-400_3.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "raleway-thin";
  src: url("assets/fonts/raleway/raleway-thin.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "arial";
  src: url("assets/fonts/arial/arial-bold.ttf") format("truetype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}
