@use "src/style/base";

.textContainer {
  width: min(30rem, 100%);
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  text-align: center;
  color: base.$grey-800;
}

.emphasis {
  color: base.$guidance-career;
}
