@use "src/style/base";

.boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
}

.box1 {
  flex: 5;
  display: flex;
  justify-content: center;
}
.box1Text {
  width: min(35rem, 100%);
  color: base.$white;
  letter-spacing: 0.25rem;
  word-spacing: 0.5rem;
}

.box2 {
  flex: 2;
}

.box3 {
  flex: 5;
}
