@use "src/style/base";

span.checkbox {
  padding-top: 0;
  padding-bottom: 0;
}

.selective {
  label {
    cursor: default;

    span {
      cursor: default;
    }
  }
}
