@use "src/style/base";

.linearDisplayContainer {
  position: relative;
}

.linearDisplay {
  height: 3rem;
  border-radius: 1rem;
  background-color: base.$grey-400;
}

.linearDisplayLabel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.label {
  font-size: 1.5rem;
  color: base.$white;
  font-weight: bold;
}
