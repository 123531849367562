@use "src/style/base";
@import "src/style/breakpoints";

.page {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 0;
}

.imageContainer {
  padding: 0 2rem;
  align-self: center;
  max-width: 430px;
  width: 100%;

  img {
    width: 100%;
    min-width: 250px;
    max-width: 430px;
  }
}

.textContainer {
  width: min(240px, 100%);
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4rem;

  div {
    min-height: 125px;
  }

  p {
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.rightBox {
  text-align: right;
}

.blueBox {
  p {
    color: base.$guidance-mitnorm;
  }
}

.redBox {
  display: none;

  p {
    color: base.$guidance-career;
  }
}

.redBox.bottom {
  display: block;
}

.greenBox {
  p {
    color: base.$guidance-personnel;
  }
  display: block;
}
.greenBox.bottom {
  display: none;
}

.yellowBox {
  p {
    color: base.$yellow;
  }
}

@include media-breakpoint-down(md) {
  .page {
    flex-direction: column;
  }

  .imageContainer {
    padding: 2rem 0;
    max-width: 300px;
  }

  .textContainer {
    flex-direction: row;
    gap: 1rem;
  }

  .redBox {
    display: block;
  }

  .redBox.bottom {
    display: none;
  }

  .greenBox {
    display: none;
  }

  .greenBox.bottom {
    display: block;
  }
}
