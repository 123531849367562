@use "src/style/base";

.cockpitCard {
  background: base.$grey-200;
  transition: all 0.2s ease-in-out;
  min-height: 12rem;
  border-radius: 1rem;
  flex-basis: 100%;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .titleRow {
    padding: 0 1rem;
    align-items: center;
  }

  .icon {
    display: flex;
    svg {
      fill: base.$primary-color;
    }
  }

  .title {
    flex-basis: 100%;
    text-align: left;
    padding: 0 1rem;
  }

  .alignCenter {
    text-align: center;
  }

  .maximize {
    color: base.$grey-500;
    font-size: 1.25rem;
    align-self: flex-start;
    margin-top: 1rem;
  }
}
