@use "src/style/base";

.menuElement {
  cursor: pointer;
  text-align: center;
}

p.text {
  color: base.$grey-500;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 1.5rem 0;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
}

p.text.selected {
  color: base.$primary-color;
}

p.text.selected:hover {
  border-bottom-color: transparent;
}

p.text:hover {
  border-bottom-color: base.$grey-500;
}

svg.circle {
  color: base.$grey-300;
  margin-top: 1.5rem;
  font-size: 1.2rem;
}

svg.circle.selected {
  color: base.$secondary-color;
}
