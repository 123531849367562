@use "src/style/base";

.tabPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  gap: 1rem;
  padding-bottom: 2rem;
}

.content,
.controls {
  text-align: left;
}

.content {
  padding-top: 3rem;
}

.controls {
  padding-top: 2rem;
  display: flex;
  gap: 1rem;
}

@include base.media-breakpoint-down(md) {
  .content {
    padding-top: 1rem;
  }
}
