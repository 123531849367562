@use "src/style/base";

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 2rem 2rem 6rem;
  margin-left: 2rem;
  max-width: 28rem;
  height: 100%;
}

.text {
  color: base.$grey-800;
  background-color: base.$white;
  box-shadow: base.$white 0 5px 20px;
}

.emphasis {
  color: base.$guidance-career;
}
