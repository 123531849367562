@use "src/style/base";

.dinScoreContent {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.linearDisplayBox {
  padding: 0 2.5rem;
}

.subtitle {
  font-size: 1.3rem;
  color: base.$primary-color;
}

.dinLevelResultTitleBox {
  display: flex;
  justify-content: space-between;
  h3,
  h6 {
    margin: 0.25rem;
  }
}
