@use "src/style/base";

.uebersichtWrapper {
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}

.waehlerBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: min(25rem, 50%);

  .waehler {
    width: 100%;
  }

  .neuerKunde {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-top: 3rem;
  }
}

.buttonBox {
  width: 100%;
  margin-top: 2rem;
}

@include base.media-breakpoint-down(md) {
  .uebersichtWrapper {
    flex-direction: column;
    align-items: center;
  }

  .waehlerBox {
    width: 100%;
  }
}

.error {
  margin-top: 1rem;
}
