@use "src/style/base";

.page {
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  max-width: 700px;
  img {
    width: 100%;
  }
}
