@use "src/style/base";
@import "src/style/breakpoints";

.chartContainer {
  display: flex;
  justify-content: center;
}

.chart {
  img {
    max-width: 1060px;
    width: 100%;
  }
}

.page {
  align-items: center;
  height: 100%;
}

.row {
  align-items: center;
  width: 100%;
}
.matchingBox {
  flex: 1;
}

.mainText {
  text-align: center;
  color: base.$grey-800;
}

.matchingImageContainer {
  padding: 2rem 4rem;
  img {
    max-width: 150px;
    width: 100%;
  }
}

.lightText {
  color: base.$grey-700;
}

.lightTextLeft {
  text-align: right;
}

@include media-breakpoint-down(md) {
  .row {
    flex-direction: column;
  }

  .lightText,
  .lightTextLeft {
    text-align: center;
  }

  .mainText {
    margin-left: 0;
  }
}
