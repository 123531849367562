@use "src/style/base";

.selectButton {
  margin: 0 10px 10px 10px;
}

.neuerKunde {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}

.newKundeLink {
  cursor: pointer;
}

.warningText {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.error {
  margin-top: 1rem;
}
