@use "src/style/base";
@import "src/style/breakpoints";

.subPage {
  height: 100%;
}

.titleContainer {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}

.title {
  font-family: raleway-thin;
  font-size: 2rem;
  color: base.$guidance-title;
  font-weight: bold;
}

.subTitle {
  font-family: arial;
  color: base.$guidance-subtitle;
  text-align: center;
  letter-spacing: 5px;
  font-size: 0.8rem;
}
