@use "src/style/base";

.names {
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
}

.line {
  padding: 0.5rem 1rem;
}

.entry {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 1rem;
}

.closeDialogButton {
  position: absolute;
  right: 0;
  margin-right: 0.75rem;
  z-index: 2;
}

.emptyMessage {
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
}
