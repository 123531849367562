@use "src/style/base";

.imageContainer {
  display: flex;
  justify-content: center;
  padding: 1rem;
  img {
    max-width: 720px;
    width: 100%;
  }
}

.backgroundClass {
  background-repeat: repeat;
  background-size: unset;
}
