@use "src/style/base";

.backgroundImage {
  background-position: unset;
  background-position-x: center;
  background-size: cover;
}

.boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
}

.box1 {
  flex: 5;
  display: flex;
  justify-content: center;
}
.box1Text {
  width: min(35rem, 100%);
  color: base.$white;
  letter-spacing: 0.25rem;
  word-spacing: 0.5rem;
}

.box2 {
  flex: 2;
}

.box3 {
  flex: 5;
}

.box3Container {
  gap: 1rem;
}

.textBulletText {
  color: base.$white;
  width: min(25rem, 100%);
  letter-spacing: 0.1rem;
  word-spacing: 0.25rem;
  margin-bottom: 0.5rem;
}
