@use "src/style/base";

.partnerStack {
  align-items: flex-start;
}

.displayAsNew {
  background-color: base.$selective-selected-background;
}

.displayAsDeleted {
  background-color: base.$selective-deleted-background;
}
