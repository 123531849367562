@use "src/style/base";

$ifiBannerHeight: 84px;

.overviewWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overviewContainer {
  width: 100%;
}

.overviewBlock {
  background-color: base.$grey-200;
  min-height: 180px;
  margin: 1rem;
  cursor: pointer;
}

.overviewBlock:last-child {
  margin-bottom: 0;
}

.mainImage {
  width: 180px;
  height: 180px;
}

.overviewBlockContent {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-top: 0.75rem;
}

.overviewBlockContentTextContainer {
  width: 100%;
  padding: 0.5rem 2rem;
}

.overviewBlockContentText {
  font-size: 0.9rem;
  line-height: 1.75rem;
  letter-spacing: 0.15rem;
  text-align: left;
}

.reverse {
  text-align: right;
}

.logoImage {
  width: 130px;
}

.myviCircle {
  position: absolute;
  border-radius: 50%;
  background-color: base.$white;
  height: 300px;
  width: 300px;
  top: calc(50% - $ifiBannerHeight / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: base.$grey-900 0 0 10px;
}

.myviLogo {
  position: absolute;
  width: 160px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ifiBanner {
  height: $ifiBannerHeight;
  background-color: #a7b1bd;
  margin: 0 1rem;
  padding: 0 1rem;
  cursor: pointer;
}

.ifiBannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.ifiText {
  font-weight: normal;
  color: base.$white;
}

@include base.media-breakpoint-down(lg) {
  .myviCircle {
    display: none;
  }

  .overviewContainer {
    width: min(600px, 100%);
  }

  .overviewBlockContentText {
    font-size: 0.9rem;
    line-height: 1.25rem;
    letter-spacing: 0.15rem;
  }
}

@include base.media-breakpoint-down(md) {
  .overviewBlockContentText {
    font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: 0.15rem;
  }
}

@include base.media-breakpoint-down(sm) {
  .overviewBlockContentText {
    font-size: 0.75rem;
  }
}
