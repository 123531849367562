@use "src/style/base";

.chartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
  padding: 1rem;
  min-height: 398px;
}

.chart {
  max-width: 470px;
  img {
    width: 100%;
  }
}

.spider {
  max-width: 590px;
  width: 100%;
  img {
    width: 100%;
  }
}
