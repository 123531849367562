@use "src/style/base";
@import "src/style/breakpoints";

.page {
  padding: 2rem;
}

.logo {
  max-width: 300px;
  align-self: center;
  img {
    width: 100%;
  }
}

.cardsSection {
  padding-top: 4rem;
}

.cardsSectionTitle {
  color: base.$grey-700;
}

.cardsContainer {
  padding-top: 2rem;
  align-self: center;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: base.$guidance-company;
  max-width: 300px;
  min-height: 125px;
}

.cardLight {
  background-color: base.$guidance-company-light;
}

.cardLighter {
  background-color: base.$guidance-company-lighter;
}

.cardText {
  color: base.$grey-700;
  font-size: 1.6rem;
  text-align: center;
}

.manPowerSection {
  padding-top: 8rem;
}

.manPowerTitleContainer {
  border: 2px solid base.$grey-800;
  padding: 1rem;
}

.manPowerTitle {
  color: base.$grey-800;
  text-align: center;
  font-weight: normal;
}

.manPowerChart {
  padding: 1rem;
  align-self: center;
  max-width: 800px;

  img {
    width: 100%;
  }
}

.blogSection {
  padding-top: 2rem;
}

.blogTitle {
  color: base.$grey-700;
  font-weight: normal;
  text-align: center;
}

.blogSeparator {
  border-bottom: 5px solid base.$grey-700;
  align-self: center;
  width: min(10rem, 100%);
}

.blogText {
  font-weight: normal;
  color: base.$grey-700;
  text-align: center;
  padding: 0 10%;
}

.blogChallengesArticle,
.blogFinancialGuidance,
.blogEducation {
  max-width: 600px;
  align-self: center;
  padding: 1rem;
  img {
    width: 100%;
  }
}

.blogEducation {
  max-width: 800px;
}

.slogan {
  padding-top: 2rem;
  img {
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .blogText {
    padding: 0 1rem;
  }
}
