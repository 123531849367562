.buttonBox {
  margin-top: 1em;
}

.feedbackBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  max-width: 100%;
  width: 20em;
  min-width: 20ch;
}

.congratulationsBox {
  background-color: green;
}

.errorBox {
  margin-top: 1rem;
}

.textField {
  max-width: 25em;
  width: 100%;
  box-sizing: content-box;
  border-radius: 0;
  font-size: 1rem;
  white-space: normal;
  opacity: 0.7;
  text-align: center;
}

.textFieldBox {
  margin-top: 1em;
}
