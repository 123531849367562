@use "src/style/base";

.chartContainer {
  display: flex;
  justify-content: center;
}

.chart {
  img {
    max-width: 940px;
    width: 100%;
  }
}
