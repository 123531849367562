@use "src/style/base";

.chartContainer {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.chart {
  min-height: 260px;
}

.chart,
.textRow {
  width: 100%;
  max-width: 781px;
  img {
    width: 100%;
  }
}

.entry {
  width: 155px;
  text-align: center;
  color: base.$primary-color;
}

.entryTitle {
  padding: 0.5rem 0;
}

.entrySubTitle {
  color: base.$grey-700;
  font-size: 0.7rem;
}
