@use "src/style/base";
@import "src/style/breakpoints";

.guidancePage {
  height: 100%;
}

.guidanceContentWrapper {
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - #{base.$appBarHeight} - #{base.$footerMinHeight});
}

.guidanceContent {
  flex-grow: 1;
}

.footer {
  bottom: 0;
}

@include media-breakpoint-down(md) {
  .guidancePage {
    min-height: calc(100vh - #{base.$appBarHeight-md});
  }
}

@include media-breakpoint-down(sm) {
  .guidancePage {
    min-height: calc(100vh - #{base.$appBarHeight-sm});
  }
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

@include media-breakpoint-down(md) {
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
}

.separator {
  border-right: 1px solid base.$grey-200;
}
